import { t } from 'i18next'
import { array, boolean, object, string } from 'yup'

export const firstQuestionSchema = () =>
  object().shape({
    experience_level: string()
      .required()
      .oneOf(['own-solution', 'competitor', 'landingi', 'beginner']),
    builder: string().when('experience_level', {
      is: 'competitor',
      then: string().required(t('form.validation.isRequired')),
      otherwise: null
    })
  })

export const secondQuestionSchema = () =>
  object().shape({
    purpose_signup: string().required(),
    otherName: string().when('purpose_signup', {
      is: 'forOther',
      then: string().required(t('form.validation.isRequired')),
      otherwise: null
    })
  })

export const thirdQuestionSchema = () =>
  object().shape({
    industry_reg_survey: string().required()
  })

export const fourthQuestionSchema = () =>
  object()
    .shape({
      ads: boolean(),
      'social-media': boolean(),
      'direct-sales': boolean(),
      'own-process-optimisation': boolean()
    })
    .test('isAtLeastOneCheckboxChecked', ' ', function (object) {
      if (
        object.ads ||
        object['social-media'] ||
        object['direct-sales'] ||
        object['own-process-optimisation']
      ) {
        return true
      }

      return this.createError({
        path: 'ads | social-media | direct-sales | own-process-optimisation'
      })
    })

export const fifthQuestionSchema = () =>
  object().shape({
    marketing_stock: array().of(string()).min(1).required()
  })

export const sixthQuestionSchema = () =>
  object()
    .shape({
      'product-updates': boolean(),
      newsletter: boolean()
    })
    .test('isAtLeastOneCheckboxChecked', ' ', function (object) {
      if (object['product-updates'] || object.newsletter) {
        return true
      }

      return this.createError({
        path: 'product-updates | newsletter'
      })
    })

export default [
  firstQuestionSchema,
  secondQuestionSchema,
  thirdQuestionSchema,
  fourthQuestionSchema,
  fifthQuestionSchema,
  sixthQuestionSchema
]
