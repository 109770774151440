import useSteps from '@pages/Landings/routes/Landings/CreateLandingWizard/helpers/useSteps'
import { Button, Heading, Paragraph, Spacer, Spreader } from '@ui-kit'
import { FC, Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

import { useWizardContext } from '../../contexts'
import styles from '../common.module.scss'
import { StyleBox } from './StyleBox'
import { CustomColor } from './StyleBox/CustomColor/CustomColor'
import { CustomFont } from './StyleBox/CustomFont/CustomFont'

export const FifthStep: FC = () => {
  const [view, setView] = useState('default')
  const { t } = useTranslation()
  const { formik } = useWizardContext()
  const { values } = formik
  const { nextStep } = useSteps()

  const { style } = values

  const defaultView = () =>
    style && (
      <div className={styles.grid}>
        <StyleBox palette={0} />
        <StyleBox palette={1} />
        <StyleBox palette={2} />
        <StyleBox palette='custom' onClick={() => setView('custom')} />
      </div>
    )

  const customView = () => (
    <Fragment>
      <Row alignItems='center'>
        <Button
          icon='icon-arrow-left'
          variant='text-neutral'
          onClick={() => setView('default')}
        />

        <Spreader spread={10} />

        <Heading level={3}>
          {t('landings.create.wizard.landing.modal.custom.style.title')}
        </Heading>
      </Row>

      <Spacer space={15} />

      <div className={styles.customSettingsGrid}>
        <CustomColor />
      </div>

      <Spacer space={15} />

      <div className={styles.customSettingsGrid}>
        <CustomFont />
      </div>
    </Fragment>
  )

  return (
    <div>
      <Heading level={1} weight={600}>
        {t('landings.create.wizard.landing.modal.what.colors')}
      </Heading>
      <Spacer space={20} />
      <Paragraph size={14} color='neutral-6'>
        {t('landings.create.wizard.landing.modal.what.colors.desc')}
      </Paragraph>
      <Spacer space={20} />

      {view === 'default' ? defaultView() : customView()}
      <Spacer space={30} />
      <Row justifyContent='end'>
        <Button icon='icon-arrow-right' onClick={nextStep}>
          {t('landings.create.wizard.landing.modal.go.to.topic')}
        </Button>
      </Row>
      <Spacer space={15} />
    </div>
  )
}
